body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.cursor-pointer {
  cursor: pointer;
}

.w-10-per {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.w-20-per {
  width: 20% !important;
  min-width: 20% !important;
  max-width: 20% !important;
}

.w-30-per {
  width: 30% !important;
  min-width: 30% !important;
  max-width: 30% !important;
}

.w-40-per {
  width: 40% !important;
  min-width: 40% !important;
  max-width: 40% !important;
}

.grid-action-btn { 
  justify-content: left !important;
}


.MuiDataGrid-columnHeadersInner, .MuiDataGrid-columnHeadersInner > div {
  width: 100% !important;
}

.MuiSelect-select {
  text-align: left;
  text-transform: capitalize !important;
}

.small-input .MuiInputBase-root {
  max-height: 38px !important;
}

.filter-input .MuiInputBase-root {
  max-height: 48px !important;
}
.full-width{
  width: 100% !important;
}

.margin-5{
  margin-top: 5px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.leave-action { 
  padding-top: 0px !important;
  height: 34px;
}